import './Header.css';
import React from 'react';

import ProgressBar from './ProgressBar';

const Header = ({category, clueIndex}) => {
  return (
    <div id='header'>

      <div id='header-column1'></div>
      <div id='header-column2'>
        <div id="category">
            <div id='category-name'>{category.categoryName}</div>
            <div id='category-comment'>{category.categoryComment}</div>
            <div id='year'>{category.categoryYear}</div>
        </div>
        <ProgressBar category={category} index={clueIndex}/>
      </div>
        
      <div id='header-column3'></div>
          

    </div>
  );
};

export default Header;