import React, { useState, useEffect } from 'react';

import axios from 'axios';

import Header from './Header';
import Clue from './Clue';
import Answer from './Answer';
import Input from './Input';
import Dashboard from './Dashboard';

import Button from './Button';

import MenuScreen from './MenuScreen';
import CategorySelectionScreen from './CategorySelectionScreen';


const Quiz = () => {

    //Variables

    const [categories, setCategories] = useState([]);

    const [clueIndex, setClueIndex] = useState(0);
    const [categoryIndex, setCategoryIndex] = useState(0);

    const [response, setResponse] = useState("");

    const screens = ["quiz", "categorySelection", "menu"];
    const [screen, setScreen] = useState(screens[2]);

    const [years, setYears] = useState({
        startYear: 0,
        endYear: 0
    });


    //Loading from localStorage
    useEffect(() => {
    }, [])

    //Writing to localStorage
    useEffect(() => {
        if (categories.length > 0) localStorage.setItem('cat', JSON.stringify(categories));

    }, [categories])

    useEffect(() => {
        localStorage.setItem('index', clueIndex);


    }, [clueIndex])


    //Fetches and returns a category object
    const apiToCategories = async (count) => {

        console.log(years.endYear);

        const response = await fetch(`https://danielcarriere.ca/trivia/api/?count=${count}&startYear=${years.startYear}&endYear=${years.endYear}`);
        const categoryRaw = await response.json();

        console.log(categoryRaw);

        const categories = categoryRaw.map((category) => ({
            categoryName: category.category.name,
            categoryComment: category.category.comment,
            categoryYear: category.category.year,
            clues: category.clues.map((clue, i) => ({
                correct: false,
                answered: false,
                key: Math.random(),
                clue: clue.clue,
                answer: clue.answer.toUpperCase(),
                id: clue.id,
                response: '',
                opened: i === 0 ? true : false
            }))
        }));

        return categories;
    }

    //Set new category
    const nextCategory =  async () => {
        const data = (await apiToCategories(1))[0];
        setCategories((prevCategories)=>[data, ...prevCategories]);
        setClueIndex(0);
    }

    //Set new category from selection screen
    const nextCategoryFromSelection = (category) => {
        setCategories((prevCategories)=>[category, ...prevCategories]);
        setClueIndex(0);
        setScreen(screens[0]);
    }

    //Starts game
    const start = (years) => {
        console.log('start');

        setYears(years);
        setScreen(screens[0]);


        const storedItem = localStorage.getItem('cat');
        if (storedItem){
            setCategories(JSON.parse(storedItem));
            setClueIndex(parseInt(localStorage.getItem('index')));
        }else
            nextCategory();

        
    }
    

    //Submit answer
    const submitResponse = (e, bool) => {
        e.preventDefault();
        
        axios.post('https://danielcarriere.ca/trivia/api', {category: categories[categoryIndex].categoryName, clue: categories[categoryIndex].clues[clueIndex].clue, response: bool ? response : 'IDK', correctAnswer: categories[categoryIndex].clues[clueIndex].answer})
            .then( res => {
                //Sets cateogry to correct or incorrect
                setCategories((prevCategories)=>{
                    return [
                        ...prevCategories.slice(0, categoryIndex), {
                            ...prevCategories[categoryIndex],
                            clues: prevCategories[categoryIndex].clues.map((clue, i) => {
                                if (i === clueIndex)
                                    return {...clue, correct: res.data === "yes" ? true : false, answered: true, response: response}
                                return clue
                            })
                        },
                        ...prevCategories.slice(categoryIndex+1)
                    ]
                })

                link();
                
                setTimeout(()=>{
                    changeClueIndex(1);
                    setResponse('');
                }, 1000)
            })
    }

    //Sets clue index to index of first unanswered clue
    const goToFirstUnanswered = (i) => {
        for (let j=0; j<categories[i].clues.length; j++){
            if (!categories[i].clues[j].answered){
                setClueIndex(j);
                break;
            }
            setClueIndex(0);
        }
    }

    //Sets category index
    const changeCategoryIndex = (i) => {
        setCategoryIndex(i);
        goToFirstUnanswered(i);
    }
    
    const changeClueIndex = (dir) => {

        //If clueIndex exceeds category length
        if (clueIndex + dir >= categories[categoryIndex].clues.length){
            //If latest category generate new category
            if (categoryIndex === 0){
                setTimeout(()=>{
                    nextCategory();
                }, 1000);
            //If not latest category go to next category
            }else{
                setCategoryIndex((prevCategoryIndex)=>prevCategoryIndex - 1);
                setClueIndex(0);
            }
        //If clueIndex is less than 0
        }else if(clueIndex + dir < 0){
            //If last category maintain 0 clueIndex
            if (categoryIndex === categories.length -1){
                setClueIndex(0);
            //If not last category go to previous category
            }else{
                setCategoryIndex((prevCategoryIndex)=>prevCategoryIndex + 1);
                setClueIndex(categories[categoryIndex + 1].clues.length - 1);
            }
        //If not a bounds case increment or decrement clueIndex accordingly
        }else{
            setClueIndex((prevClueIndex) => prevClueIndex + dir);
        }
    }

    //Clears local storage
    const clearCategories = () => {
        setCategories([]);
        setCategoryIndex(0);
        nextCategory();
        localStorage.clear();
    }

     //Sets screen to category selection
     const goToCategorySelection = () => {
        setScreen(screens[1]);
    }

    
    const link = () => {
        console.log('test');

        axios.post('https://danielcarriere.ca/trivia/api/link', {id: categories[categoryIndex].clues[clueIndex].id})
        .then( res => {
            console.log(res.data);

            setCategories((prevCategories)=>{
                return [
                    ...prevCategories.slice(0, categoryIndex), {
                        ...prevCategories[categoryIndex],
                        clues: prevCategories[categoryIndex].clues.map((clue, i) => {
                            if (i === clueIndex)
                                return {...clue, answer: res.data}
                            return clue
                        })
                    },
                    ...prevCategories.slice(categoryIndex+1)
                ]
            })
        })
    }
    
    //Components
    return (
        <div id='quiz'>

                    {
                        screen === "menu" ?
                            <MenuScreen start={start}/>
                        : screen === "categorySelection" ?
                            <CategorySelectionScreen apiToCategories={apiToCategories} nextCategoryFromSelection={nextCategoryFromSelection}/>
                        :
                            <>
                                {categories.length > 0 ?
                                    <>
                                        <Header category={categories[categoryIndex]} clueIndex={clueIndex} />
                                        <Clue category={categories[categoryIndex]} clueIndex={clueIndex} categoryIndex={categoryIndex} changeClueIndex={changeClueIndex}/>
                                        <Answer category={categories[categoryIndex]} index={clueIndex} />
                                        <Input category={categories[categoryIndex]} index={clueIndex} submitResponse={submitResponse} response={response} setResponse={setResponse}/>
                                        
                                        <Dashboard categories={categories} index={clueIndex} clearCategories={clearCategories} categoryIndex={categoryIndex} changeCategoryIndex={changeCategoryIndex} goToCategorySelection={goToCategorySelection}/>
                                    </> : <></>
                                }
                                
                            </>
                    }
        </div>
    );
};

export default Quiz;