import './CategorySelectionScreen.css';
import React, { useState, useEffect } from 'react';

const CategorySelectionScreen = ({apiToCategories, nextCategoryFromSelection}) => {


  const [categories, setCategories] = useState(null);

  const fetchCategories = async () => {

    setCategories(await apiToCategories(8));
  }

  useEffect(() => {
    
    fetchCategories();
  }, [])


  return (
    <div id='category-selection-screen'>
      <div id='category-selection-box'>

      {
        categories != null ?
          categories.map( (category) => (
            <div className='category-selection-category' key={Math.random()} onClick={() => {nextCategoryFromSelection(category)}}>{category.categoryName}</div>
          ))
        :
          <></>
      }

        

      </div>

    </div>
  );
};

export default CategorySelectionScreen;

