import './MenuScreen.css';
import React, {useState, useEffect} from 'react';
import Button from './Button';



const MenuScreen = ({start}) => {

    const [stats, setStats] = useState(null);    
    const [years, setYears] = useState(null);

    const run = async () => {

        const response = await (await fetch(`https://danielcarriere.ca/trivia/api/stats`)).json();

        setStats(response);
        setYears({
            startYear: 0,
            endYear: response.seasonCount-1
        });
    }

    useEffect(() => {
        run();
    }, [])


    return (
            <div id="menu-screen" >
                <div id='title'>
                    <div id='title-box'>
                        <div id='count-columns'>
                            <div className='count'>{`${stats ? stats.catCounts.slice(years.startYear, years.endYear+1).reduce((sum, count) => (sum + count), 0).toLocaleString() : ''} categories`}</div>
                            <div className='count'>{`${stats ? stats.clueCounts.slice(years.startYear, years.endYear+1).reduce((sum, count) => (sum + count), 0).toLocaleString() : ''} clues`}</div>
                        </div>
                        <div id='title-text'>TRIVIA ARCHIVE</div>
                    </div>
                </div>
                <div id='description' onClick={()=>{console.log(years); console.log(stats)}}>Select the date range of your clues below</div>
                    
                <div id='range'>
                    <select id="start-year" onChange={(e) => setYears({...years, startYear: parseInt(e.target.value)})}>
                        {Array.from({length: stats ? stats.seasonCount : 0}).map((year, i) => (
                                <option selected={i===0} value={i}>{stats ? stats.startYear + i : 0}</option>
                            ))
                        }
                        
                    </select>

                    to

                    <select id="end-year" onChange={(e) => setYears({...years, endYear: parseInt(e.target.value)})}>
                        {Array.from({length: stats ? stats.seasonCount : 0}).map((year, i) => (
                                <option selected={i===stats.seasonCount-1} value={i}>{stats ? stats.startYear + i : 0}</option>
                            ))
                        }
                    </select>
                </div>

                <div id='go'>
                    <div id='go-text' onClick={()=>start(years)}>START</div>
                </div>
                
            </div>
    );
};

export default MenuScreen;