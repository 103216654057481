import './Answer.css';
import React from 'react';

import check from '../Assets/check.png';
import x from '../Assets/x.png';


const Answer = ({category, index}) => {
  return (
    <div id='answer'>
      {category.clues[index].answered ?
        <div id='answer-columns'>
          <div id='answer-column1'>
            <div className={`answer-icon ${category.clues[index].correct ? 'answer-icon-gold' : ''}`}>
              <img className='small-icon' src={category.clues[index].correct ? check : x}/>
            </div>
          </div>
          <div id='answer-column2'>
            <div id='answer-box' dangerouslySetInnerHTML={{ __html: category.clues[index].answer }}></div>
          </div>
          <div id='answer-column3'>C</div>
        </div>
        :
        <></>
      }
    </div>
  );
};

export default Answer;