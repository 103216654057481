import './Button.css';

const Button = ({children, size, callback}) => {


    return (
            <div className="button" onClick={callback} style={{height: `${size}vh` /*,aspectRatio: children.type === 'img' ? '1 / 1' : ''*/} }>{children}</div>
    );
};

export default Button;