import './Dashboard.css';
import React, { useState } from 'react';

import ProgressBar from './ProgressBar';
import Button from './Button';
import refresh from '../Assets/refresh.png';

const Dashboard = ({categories, index, clearCategories, categoryIndex, changeCategoryIndex, goToCategorySelection}) => {


    return (
            <div className="dashboard" onClick={()=>console.log(categories)}>
                <div className='dashboard-categories' >
                    {categories.map((category, i) => (
                            <div className={`dashboard-category-line ${categoryIndex === i ? 'dashboard-category-line-selected' : '' }`} onClick={()=>changeCategoryIndex(i)}>
                                <div className='dashboard-category-progress-bar'>
                                    <ProgressBar category={category} index={categoryIndex === i ? index : null} />
                                </div>
                                <div className='dashboard-category-name'>{category.categoryName.length > 30 ? category.categoryName.slice(0, 30) + "...": category.categoryName}</div>
                            </div>
                        ))
                    }
                </div>
                <div id='dashboard-buttons'>
                    <Button size={6} callback={clearCategories}> <img src={refresh}/> <div>Clear</div> </Button>
                    <Button size={6} callback={goToCategorySelection}><div>New Category</div></Button>
                </div>
                
            </div>
    );
};

export default Dashboard;