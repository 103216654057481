import './Input.css';
import React, { useEffect, useRef } from 'react';
import Button from './Button';

import rightArrow from '../Assets/right-arrow.png';
import questionMark from '../Assets/question-mark.png';



const Input = ({category, index, submitResponse, response, setResponse}) => {

  const inputRef = useRef(null); 

  const onFocus = () => {
    inputRef.current.style.opacity = 0;
    setTimeout(() => {inputRef.current.style.opacity = 1}, 5 );
  }


  return (
    <div id='input'>
      <div id='input-column1'>
        {!category.clues[index].answered ? 
          <Button size={6} callback={(e)=>submitResponse(e, false)}>
            <img src={questionMark}/>
          </Button>
        : <></>
        }
        
      </div>
      <div id='input-column2'>
        <form onSubmit={(e)=>submitResponse(e, true)}>
          <input type="text" id="input-box" ref={inputRef} value={!category.clues[index].answered ? response : category.clues[index].response} onChange={e => setResponse(e.target.value.toUpperCase())} onFocus={onFocus} readOnly={category.clues[index].answered}></input>
        </form>
      </div>
      
      <div id='input-column3'>
        {!category.clues[index].answered ?
          <Button size={6} callback={(e)=>submitResponse(e, true)}>
            <img src={rightArrow}/>
          </Button>
          : <></>
          }
      </div>    
        
    </div>
  );
};

export default Input;