import './Clue.css';
import React from 'react';

import leftTriangle from '../Assets/left-triangle.png';
import rightTriangle from '../Assets/right-triangle.png';

const Clue = ({category, clueIndex, categoryIndex, changeClueIndex}) => {
  
  return (
    <div id='clue'>
        
        <div className='arrow arrow-left' onClick={()=>changeClueIndex(-1)}>
          <div className='arrow-box'>
            <img src={leftTriangle}/>
          </div>
        </div>
        
        <div id="clue-text" dangerouslySetInnerHTML={{ __html: category.clues[clueIndex].clue }}></div>

        {!(categoryIndex === 0 && clueIndex === category.clues.length - 1)?
          <div className='arrow arrow-right' onClick={()=>changeClueIndex(1)}>
            <div className='arrow-box'>
              <img src={rightTriangle}/>
            </div>
          </div>
        : <div className='arrow arrow-right'><img src={rightTriangle}/></div>
        }
    </div>
  );
};

export default Clue;

