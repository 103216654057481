import React from 'react';

const ProgressBar = ({category, index, callback}) => {
  return (
    <div className='progress-bar' key={Math.random()} onClick={callback}>
        
        { category.clues.map((clue, i) => (
                <div className={
                    `progress-bar-square
                    ${
                        i === index ? 'progress-bar-square-selected' : ''
                    }
                    ${
                        clue.answered? `${clue.correct ? 'progress-bar-square-correct' : 'progress-bar-square-incorrect'}` : ''
                    }
                    `
                }key={clue.key}></div>
            ))
        }

    </div>
  );
};

export default ProgressBar;